import PropTypes from "prop-types";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";

const PortfolioItem = ({ portfolio }) => {
    return (
        <div className="single-portfolio">
            <LightgalleryItem
                group="any"
                src={process.env.PUBLIC_URL + portfolio.homeImage}
            >
                <div className="thumbnail">
                    <div className="overlay">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                `img/portfolio/` +
                                portfolio.title +
                                `_thumb.jpg`
                            }
                            alt="backonfish portfolio"
                        />
                    </div>
                </div>
            </LightgalleryItem>
            <div className="content">
                <h3 className="title">{portfolio.title}</h3>
                <p className="desc">{portfolio.excerpt}</p>
            </div>
        </div>
    );
};

PortfolioItem.propTypes = {
    portfolio: PropTypes.object,
};

export default PortfolioItem;
