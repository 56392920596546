import { Link } from "react-router-dom";
import FooterLogo from "../../components/footer-logo";

const Footer = () => {
    return (
        <footer className="footer-area reveal-footer border-top-style">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="footer-content">
                            <div className="widget-item text-center">
                                <div className="about-widget">
                                    <FooterLogo
                                        image={`${process.env.PUBLIC_URL}/img/logo.png`}
                                    />
                                </div>
                                <div className="widget-copyright">
                                    <p>
                                        © 2022 <span>BaconFish</span>. Made with{" "}
                                        <i className="icofont-heart-alt"></i>
                                    </p>
                                </div>
                            </div>
                            <div className="widget-item">
                                <ul className="widget-social">
                                    <li className="social-text">
                                        <span>follow me on social</span>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/baconfish_/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="social_instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                                <div>
                                    <span>My Kit: </span>
                                    <a
                                        href="https://kit.co/BaconFish"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        kit.co/BaconFish
                                    </a>
                                </div>
                                <div>
                                    <span>Drop me a line: </span>
                                    <a href="mailto:hello@baconfish.be">
                                        hello@baconfish.be
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
